.dimmer {
  background-color: rgba(0, 0, 0, 0.53);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 2;
}

.modal-title {
  text-align: center;
  font-size: 24px;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  @media (min-width: 1025px) {
    font-size: 32px;
  }
}

.modal-background {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: fixed;
  overflow-y: auto;

  .modal-container {
    background-color: #1f1f21;
    padding: 80px;
    margin: 50px;
    width: 100%;
    max-width: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    border-radius: 10px;
    @media only screen and (max-width: 1210px) {
      flex-direction: column;
      align-items: center;
      max-width: 608px;
      padding: 40px;
      margin: 25px;
    }

    @media only screen and (max-width: 550px) {
      padding: 20px;
      margin: 18px;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: 40px;
      button {
        position: relative;
        z-index: 3;
      }
      @media only screen and (max-width: 1210px) {
        margin-left: 0;
      }
      @media only screen and (max-width: 550px) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .modal-container > div:first-child {
    @media only screen and (max-width: 1210px) {
      margin-bottom: 50px;
    }
  }
}
