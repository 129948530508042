.gadget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media only screen and (max-width: 1210px) {
    width: 204px;
  }
  @media only screen and (max-width: 550px) {
    width: 154px;
  }

  .gadget-icon-wrapper {
    width: 163px;

    @media only screen and (max-width: 1210px) {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }

  .caption {
    margin-top: 10px;
  }
}
