.header-container {
  height: 90px;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;

  .header-content-wrapper {
    margin: 0 170px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    max-width: 1100px;
    min-width: 443px;
    @media (max-width: 1210px) {
      margin: 0 64px;
      min-width: 443px;
    }
    @media only screen and (max-width: 550px) {
      min-width: 300px;
      margin: 0 30px;
    }
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .button-group {
    display: flex;
    align-items: center;
    .price {
      margin-right: 20px;
    }
  }
}
