.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 260px;
  img {
    width: 215px;
  }
  @media only screen and (max-width: 1210px) {
    height: auto;
    margin-bottom: 8vh;
    img {
      width: 30vw;
      min-width: 320px;
    }
  }
}
