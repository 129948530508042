.features-container {
  padding: 16vh 170px;
  @media only screen and (max-width: 1210px) {
    padding: 10vh 64px;
  }
  @media only screen and (max-width: 550px) {
    padding: 8vh 30px 12vh 30px;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;

  .cards-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    min-width: 443px;
    @media only screen and (max-width: 550px) {
      min-width: auto;
      margin: 0 30px;
    }
    margin-bottom: 12vh;
    & > *:not(:last-child) {
      margin-right: 50px;
      @media only screen and (max-width: 1210px) {
        margin-bottom: 80px;
      }
    }
    @media only screen and (max-width: 1210px) {
      flex-direction: column;
    }
  }

  .panel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1f1f21;
    width: 100%;
    max-width: 1100px;
    min-width: 443px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.07);
    padding: 42px;
    box-sizing: border-box;
    @media only screen and (max-width: 1210px) {
      align-items: flex-start;
    }
    @media only screen and (max-width: 550px) {
      min-width: auto;
      padding: 35px;
      margin-top: 90px;
      flex-direction: column;
      align-items: center;
    }

    .panel-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0 20px;
      @media only screen and (max-width: 1210px) {
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
      }
      @media only screen and (max-width: 550px) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }
    .panel-image {
      max-width: 185px;
      margin-right: 35px;
      @media only screen and (max-width: 550px) {
        margin-bottom: 35px;
        margin-right: 0;
      }
    }
    .panel-text {
      .panel-text-heading {
        margin-bottom: 9px;
      }
      @media only screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
    }
    .panel-button {
      flex-shrink: 0;
      margin: 0 20px;
      @media only screen and (max-width: 1210px) {
        margin: 20px 0 0 0;
      }
      @media only screen and (max-width: 550px) {
        align-self: center;
      }
    }
  }
}
