/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* i.e. 1rem */
  background: #19191b;
}
body {
  font-family: Lato, "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hs-banner-optimization-animation [aria-label="Decline"] {
  display: none !important;
}
