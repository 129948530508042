.main-container {
  display: flex;
  background: url("../../img/gradient.jpg") center;
  background-size: cover;
  align-items: center;
  justify-content: center;
  padding: calc(10vh + 90px) 0 10vh 0;
  @media only screen and (max-width: 1210px) {
    padding: calc(7vh + 90px) 0 8vh 0;
  }
  @media only screen and (max-width: 550px) {
    padding: calc(7vh + 90px) 0 8vh 0;
  }

  .main-content-wrapper {
    margin: 0 170px;
    display: flex;
    width: 100%;
    max-width: 1100px;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @media only screen and (max-width: 1210px) {
      flex-direction: column;
      margin: 0 64px;
    }
    @media only screen and (max-width: 550px) {
      margin: 0 30px;
    }
  }

  .main-text-container {
    max-width: 550px;
    @media only screen and (max-width: 1210px) {
      max-width: none;
      margin-bottom: 20px;
    }

    .main-text-heading {
      margin: 24px 0 0 -3px;

      max-width: 550px;
    }
    .main-text {
      margin: 32px 0;
      max-width: 447px;
      @media only screen and (max-width: 1210px) {
        max-width: none;
      }
      .change-location {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .main-text-footer {
      margin-top: 14px;
    }
    .line-through {
      text-decoration: line-through;
    }
    .price {
      margin-top: 5px;
    }
  }

  .main-device-image {
    max-width: 447px;

    @media only screen and (max-width: 550px) {
      width: 70vw;
    }
  }
}
