#root {
  min-height: 100vh;
}

// Font color
.dark-color {
  color: #19191b;
}

.dark-gray-color {
  color: #474749;
}

.gray-color {
  color: #d1d1d1;
}

.light-gray-color {
  color: #e8e8e8;
}

.light-color {
  color: #ffffff;
}

// Font weight
.regular-weight {
  font-weight: 400;
}

.medium-weight {
  font-weight: 500;
}

.bold-weight {
  font-weight: 700;
}

// Font size
.xxs-size {
  font-size: 12px;
}

.xs-size {
  font-size: 14px;
}

.s-size {
  font-size: 16px;
  line-height: 22px;
}

.m-size {
  font-size: 18px;
  line-height: 24px;
}

.l-size {
  font-size: 20px;
}

.xl-size {
  font-size: 25px;
  line-height: 29px;
}

.xxl-size {
  font-size: 40px;
  line-height: 48px;
}

.xxxl-size {
  font-size: 50px;
  line-height: 55px;
}

// Font family

.lato {
  font-family: "Lato";
}

.sora {
  font-family: "Sora";
}

@media only screen and (max-width: 1210px) {
  // Font size
  .xxl-size {
    font-size: 35px;
    line-height: 40px;
  }

  .xxxl-size {
    font-size: 45px;
    line-height: 50px;
  }
}
@media only screen and (max-width: 550px) {
  .sm-hide {
    display: none;
  }
}
