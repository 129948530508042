.dropdown {
  min-width: 300px;
  @media only screen and (max-width: 550px) {
    min-width: auto;
    width: 100%;
    margin-bottom: 20px;
  }
  border-radius: 5px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
  overflow: visible;
  height: 40px;
  margin-right: 8px;
}

.dropdown-header {
  height: 100%;
  padding-left: 17px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-radius: 5px;
  display: none;
  margin-top: 4px;
  height: 200px;
  overflow-y: scroll;
}

.dropdown-body.open {
  display: block;
  background: white;
  position: relative;
  z-index: 4;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item,
.dropdown-header {
  font-family: "Sora";
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474749;
}

.dropdown-item:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #4c4d52;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
  margin-right: 17px;
  transform: rotate(-180deg);
}

.icon.open {
  transform: none;
}
