.card-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  @media only screen and (max-width: 1210px) {
    width: 100%;
  }

  .card-icon-wrapper {
    display: flex;
    align-items: flex-end;
    height: 45px;
  }
  .card-heading {
    margin: 14px 0;
  }
}
