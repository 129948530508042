.button {
  background-color: #19191b;
  font-family: "Sora";
  color: #fff;
  border: none;
  font-weight: 400;
  cursor: pointer;

  &.link {
    text-decoration: none;
  }
  &.active {
    background-color: #fed933;
    color: #19191b;
  }
  &.normal {
    padding: 11px 18px;
    border-radius: 20px;
    font-size: 14px;
  }
  &.medium {
    padding: 18px 28.5px 16px;
    border-radius: 27.5px;
    font-size: 16px;
    font-weight: 500;
  }
}
