.specs-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  .specs-content-wrapper {
    box-sizing: content-box;
    margin: 0 170px;
    width: 100%;
    max-width: 1100px;
    min-width: 443px;
    @media only screen and (max-width: 1210px) {
      margin: 0 64px;
    }
    @media only screen and (max-width: 550px) {
      min-width: auto;
      margin: 0 30px;
    }
    height: 100%;

    .specs-grid {
      margin-top: 80px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      row-gap: 80px;
      column-gap: 40px;
      @media only screen and (max-width: 1210px) {
        grid-template-columns: 1fr;
        row-gap: 15px;
        & > *:nth-child(even) {
          padding-bottom: 80px;
        }
      }

      @media only screen and (max-width: 1210px) and (min-width: 550px) {
        .specs-body {
          margin-left: 41px;
        }
      }

      .specs-heading {
        margin-left: 95px;
        @media only screen and (max-width: 1210px) {
          margin-left: 0;
        }
      }

      @media only screen and (max-width: 1210px) and (min-width: 550px) {
        .warranty,
        .requirements {
          margin-bottom: 25px;
        }
      }
    }

    .gadgets-grid {
      display: grid;
      grid-template-columns: 163px 163px 163px;
      gap: 24px;
      @media only screen and (max-width: 1210px) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 0;
        column-gap: 14px;
      }
      @media only screen and (max-width: 550px) {
        column-gap: 6px;
      }
    }
    .tech-specs-grid {
      @media only screen and (max-width: 1210px) and (min-width: 550px) {
        margin-top: 24px;
        margin-left: 55px;
      }
      margin-top: 10px;
      display: grid;
      grid-template-columns: 40% 60%;
      row-gap: 37px;
      .tech-specs-grid-heading {
        padding-right: 50px;
      }
      .tech-specs-grid-heading,
      .tech-specs-grid-body {
        padding-bottom: 16px;
        border-bottom: solid 1px #2d2d2f;
      }
      @media only screen and (max-width: 550px) {
        grid-template-columns: 1fr;
        row-gap: 5px;
        .tech-specs-grid-heading {
          padding-top: 26px;
          padding-bottom: 0;
          border-bottom: none;
        }
        .tech-specs-grid-body {
          padding-bottom: 12px;
        }
      }
      .indent-one {
        margin-left: 25px;
      }
      .indent-two {
        margin-left: 50px;
      }
    }
  }
}
